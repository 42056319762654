import * as React from "react";

import AppsterAwardImg from "../../assets/images/awards/appster-award.png";

const AppsterAward: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...rest }) => {
  return (
    <div {...rest}>
      <img
        className="full"
        src={AppsterAwardImg}
        alt="Appster Awards - Most Innovative App"
      />
    </div>
  );
};

export default AppsterAward;
